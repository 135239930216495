import './index.scss';
import minimap from './img/generalInterface-minimap.png';
import marketplaceMap from './img/marketplaceMiniMap.png';
import gardensMap from './img/gardensMiniMap.png';
import cvMap from './img/crystalValeMapLoRes.jpg';
import jewelMap from './img/jewelerMiniMap.png';
import docksMap from './img/docksMiniMap.png';
import cvMenu from './img/generalInterface_cv-menu.jpg';
import avatar from './img/generalInterface_avatar.jpg';
import cvMarketplace from './img/marketplace-cv.jpg';
import ragnaTrader from './img/ragnaTrader.png';
import tokens from './img/tokens.jpg';
import druidUlfur from './img/druidUlfur.png';
import gardens from './img//gardens.jpg';
import { useState } from 'react';
import cx from 'classnames';
import gardenDocs from './img/gardenDocsButton.png';
import helga from './img/helga.png';
import docks from './img/docks.png';
import jeweler from './img/jeweler.png';
import soron from './img/sorenJeweler.png';
import crystalIssuance from './img/crystalIssuanceButton.png';
import jewelerTable from './img/jewelerTable.png';
import veigar from './img/veigarDockmaster.png';

const CrystalvaleTour = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="backgroundContainer">
      <div className="tourContainer" id="CrystalValeTour">
        <div className="menuColumn">
          <div className="buttonsContainer">
            <button
              className={cx('menuButton', 'buttonRed', { active: tabIndex === 0 })}
              onClick={() => setTabIndex(0)}
            >
              General Interface
            </button>
            <button
              className={cx('menuButton', 'buttonOrange', { active: tabIndex === 1 })}
              onClick={() => setTabIndex(1)}
            >
              Marketplace
            </button>
            <button
              className={cx('menuButton', 'buttonYellow', { active: tabIndex === 2 })}
              onClick={() => setTabIndex(2)}
            >
              Gardens
            </button>
            <button
              className={cx('menuButton', 'buttonGreen', { active: tabIndex === 3 })}
              onClick={() => setTabIndex(3)}
            >
              The Jeweler
            </button>
            <button
              className={cx('menuButton', 'buttonBlue', { active: tabIndex === 4 })}
              onClick={() => setTabIndex(4)}
            >
              The Docks
            </button>
          </div>
          <div className="miniMapContainer">
            <img
              className="minimapImg generalInterface active"
              src={minimap}
              alt="Crystalvale Location"
            />
            <img
              className="minimapImg marketplace"
              src={marketplaceMap}
              alt="Marketplace Location"
            />
            <img className="minimapImg gardens" src={gardensMap} alt="Gardens Location" />
            <img className="minimapImg jeweler" src={jewelMap} alt="Jeweler Location" />
            <img className="minimapImg docks" src={docksMap} alt="Docks Location" />
          </div>
        </div>

        <div className="contentContainer">
          <div className="borderContainer">
            <div className={cx('content', 'generalInterface', { active: tabIndex === 0 })}>
              <h2>General Interface</h2>
              <p>The main view in DeFi Kingdoms is the World Map.</p>
              <div className="sideBySide crystalValeMap imgRight">
                <div className="textContainer ">
                  <p>
                    You can zoom in and out with your mouse wheel or pinch zoom on a mobile device.
                    Navigate by holding the left mouse button and dragging or panning with your
                    finger on a mobile device. Visit any specific location with a click or tap.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={cvMap} alt="Crystalvale World" />
                </div>
              </div>
              <div className="sideBySide gameMenu imgLeft">
                <div className="textContainer">
                  <p>
                    Additionally, the menu in the top right corner allows you to navigate between
                    locations directly without returning to the World Map, provides links to the
                    DeFi Kingdoms Discord server, social media, links to the documentation, and to
                    this tutorial.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={cvMenu} alt="Menu" />
                </div>
              </div>
              <div className="sideBySide avatar imgRight">
                <div className="textContainer">
                  <p>
                    In the top left, a generic character avatar is displayed along with the CRYSTAL
                    balance in your Metamask wallet and your locked rewards balance. Clicking on
                    this avatar opens a menu with a ton of useful information such as pending reward
                    totals, real-time CRYSTAL pricing, and market cap. Customized profile avatars
                    are coming soon!
                  </p>
                  <p>
                    You can also click on the inventory button below to view your inventory of
                    in-game items.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={avatar} alt="Character Avatar" />
                </div>
              </div>
            </div>
            <div className={cx('content', 'marketplace', { active: tabIndex === 1 })}>
              <h2>Marketplace</h2>
              <p>Welcome to the Marketplace, adventurer!</p>
              <img src={cvMarketplace} alt="Marketplace" />

              <div className="sideBySide ragna imgRight">
                <div className="textContainer">
                  <p>
                    This is the heart of the bustling DeFi Kingdoms economy. Here, new players
                    should speak to the Trader, Ragna. She will help you trade your AVAX tokens for
                    JEWEL and CRYSTAL, the currencies of DeFi Kingdoms.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={ragnaTrader} alt="Ragna the Trader" />
                </div>
              </div>
              <div className="sideBySide tokens imgRight">
                <div className="textContainer">
                  <p>
                    JEWEL can be staked in the <span className="bold">Ice Gardens</span> to earn
                    CRYSTAL emissions, is used for gas while in Crystalvale, and is the main
                    currency in Serendale, where you can travel from the Docks. CRYSTAL will
                    eventually be used for a variety of transactions in Crystalvale. You'll probably
                    want a supply of both, and may revisit Ragna regularly to make swaps as needed.
                  </p>
                  <p className="paragraphSml">
                    Ragna doesn’t just trade JEWEL, CRYSTAL, and AVAX, though. She functions as the
                    face of our decentralized exchange, allowing players to swap tokens with
                    extremely low fees and transaction times of only a few seconds.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={tokens} alt="tokens" />
                </div>
              </div>
              <div className="sideBySide ulfur imgLeft">
                <div className="textContainer">
                  <p>
                    You may have noticed Druid Ulfur, who’s also here in the Marketplace. Ulfur
                    helps you pair tokens and invest in Liquidity Pools, which are a key part of
                    DeFi Kingdoms. In order to get started, you can speak to Druid Ulfur to buy
                    Seeds. You buy Seeds by investing an equal value of two tokens. As you already
                    know, you can trade for those tokens with Ragna. Once you own seeds, you can
                    head to the Gardens to have them planted. Planting seeds in incentivized
                    Liquidity Pools earns you rewards.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={druidUlfur} alt="Druid Ulfur" />
                </div>
              </div>
              <p className="leftAlign centered">
                Liquidity Pooling can be a powerful tool to generate more CRYSTAL. I’ll explain more
                about this process in the <span className="bold">Gardens</span>, so when you’re
                ready, let’s head there.
              </p>
            </div>
            <div className={cx('content', 'gardens', { active: tabIndex === 2 })}>
              <h2>Gardens</h2>
              <p>
                This oasis of agrarian tranquility is the Gardens. Helga Frost-Thumb, the
                Horticulturist, oversees everything in this area, including the hopping bun-buns.
              </p>
              <img src={gardens} alt="Gardens" />
              <p className="topSpacing">
                You’ll want to take a look at the Seed Box, which shows a list of the active
                incentivized Liquidity Pools (Gardens) in DeFi Kingdoms. You can see how much is
                invested in each pool, each pool’s emissions, and the expected return (using a very
                simplified formula) from this screen. This is where you can decide which Liquidity
                Pools you are interested in joining.
              </p>
              <p className="topSpacing">
                If you’ve already purchased Seeds from Druid Ulfur in the Marketplace, you can
                deposit them in the Seed Box here. Helga will plant them immediately, and you’ll
                earn rewards over time as new CRYSTALs are grown.
              </p>
              <div className="sideBySide helga imgRight">
                <div className="textContainer">
                  <p>
                    The Horticulturist will lovingly tend your plants until all of your rewards are
                    released. You can check the Harvest area to view and claim your rewards. When
                    buying Seeds and planting in the Gardens, remember that withdrawal fees apply
                    and that a portion of rewards are time-locked. Planting in the Gardens is a
                    powerful tool in DeFi Kingdoms, so I suggest spending some time to learn about
                    Liquidity Pools and the time-locked rewards system if you aren’t already
                    familiar.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={helga} alt="Helga" />
                </div>
              </div>
              <div className="sideBySide buttons">
                <div className="textContainer">
                  Information about time locked rewards can be found{' '}
                  <span className="bold">HERE</span>
                </div>
                <a
                  href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens"
                  className="button blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={gardenDocs} alt="Garden Docs" />
                </a>
              </div>
              <div className="sideBySide buttons">
                <div className="textContainer">
                  The issuance schedule of CRYSTAL through the Gardens can be found&nbsp;
                  <span className="bold">HERE</span>.
                </div>
                <a
                  href="https://docs.defikingdoms.com/how-defi-kingdoms-works/the-gardens/ice-gardens"
                  className="button blue"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={crystalIssuance} alt="Crystal Issuance Schedule" />
                </a>
              </div>
            </div>
            <div className={cx('content', 'jeweler', { active: tabIndex === 3 })}>
              <h2>The Jeweler</h2>
              <p>
                We’ve now arrived at the Jeweler! If you’re thinking about holding onto your CRYSTAL
                for a while, and if you’d like to earn rewards while doing so, this is the right
                place to be. You can speak to Jeweler Soren to deposit CRYSTAL into his vault.
              </p>
              <img src={jeweler} alt="The Jeweler" />
              <div className="sideBySide soren imgLeft">
                <div className="textContainer">
                  <p>
                    While your CRYSTAL is deposited, a portion of the fees from the decentralized
                    exchange and a portion of fees from in-game CRYSTAL transactions are returned to
                    you as rewards. These rewards are shared based on your share of all deposits
                    with the Jeweler, so the more CRYSTAL you have in his vaults, the more rewards
                    you will accrue.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={soron} alt="Jeweler" />
                </div>
              </div>
              <div className="sideBySide jewelerRoom imgRight">
                <div className="textContainer">
                  <p>
                    Unlike in the Gardens, these
                    <br />
                    rewards are unlocked instantly. You can, of course, also withdraw your funds
                    from the Jeweler at any time and there is no withdrawal fee.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={jewelerTable} alt="Jeweler Room" />
                </div>
              </div>
            </div>
            <div className={cx('content', 'docks', { active: tabIndex === 4 })}>
              <h2>The Docks</h2>
              <p>
                These are the docks of Vithraven, where players and goods flow in and out of the
                realm. There are a variety of useful services to be found here!
              </p>
              <img src={docks} alt="The Docks" />
              <div className="sideBySide veigar imgRight">
                <div className="textContainer">
                  <p>
                    For one thing, you can always access bridging services by speaking to Veigar
                    Wavestrider, the Dockmaster. He can help you bridge more tokens onto the DFK
                    Chain, where Crystalvale lives. He can also help bridge to other chains,
                    including Harmony, where <span className="bold">DeFi Kingdoms: Serendale</span>{' '}
                    has its home.
                  </p>
                </div>
                <div className="imageContainer">
                  <img src={veigar} alt="Veigar Wavestrider" />
                </div>
              </div>
              <p>
                Speaking of Serendale, you can travel there by speaking to Veigar. He can help you
                reconfigure your wallet for Harmony and bring you to the other realm. It’s worth
                visiting Serendale, too, because there are more gameplay features live there like
                Hero Summoning and sales, professions quests, and more. Since JEWEL is the currency
                of the realm in Serendale, you might consider bridging some JEWEL before traveling
                there.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrystalvaleTour;
