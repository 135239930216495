import SubPage from '../SubPage';
import banner from './images/exploration-banner.png';
import subbanner from './images/adventuring-subbanner.png';
import challenges from './images/challenges-screen.png';
import dungeons from './images/dungeons-screen.png';
import styles from './index.module.scss';

function AdventuringPage() {
  return (
    <SubPage
      subpageIndex={2}
      heroHeader="Adventuring"
      heroBackground={banner}
      heroSubheader="Prepare for epic battles, discover hidden treasures, and become the legendary commander you were destined to be."
      mainCopyHeader="Search for Adventure"
      mainCopySubheader="Take a horse, a boat, or an airship from the capital city to the hinterlands in search of new opportunities and rewards! They’re right behind the big monsters over there."
      rightSectionHeader="Challenges"
      rightSectionCopy={
        <>
          <p>
            Direct your Heroes in PVE combat to face off against formidable monsters, testing your
            skills and ingenuity. By conquering these challenges, you'll not only hone your Heroes'
            abilities but also unlock unique rewards that could give you a significant advantage in
            future battles.
          </p>
        </>
      }
      rightSectionImage={challenges}
      leftClassName={styles.leftSection}
      leftSectionHeader="Dungeons"
      leftSectionCopy={
        <>
          <p>
            Heroes must be fierce, bold, and brave. Dungeons are the true test of your Heroes’
            mettle and, moreso, your grit as their Commander. Select your best Heroes and prove your
            worth by progressing through a series of increasingly dangerous fights to discover the
            most powerful loot—or perish trying.
          </p>
        </>
      }
      leftSectionImage={dungeons}
      centerSectionHeader="Limited-Time Events"
      centerSectionCopy={
        <>
          <p>
            On the field of battle, you never know what hurdles you may face. Be ready for
            unexpected monster encounters, intense tournaments, and competition over finite
            resources. These time-limited events require shrewd positioning of your Heroes across
            realms, putting your skills to the test. Stay vigilant, be ready for anything, and seize
            the moment to claim your legacy!
          </p>
        </>
      }
      centerSectionImage={subbanner}
      nextSectionLink="exploration"
      nextSectionLabel="World Exploration"
    />
  );
}

export default AdventuringPage;
