import { useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useDispatch, useSelector } from '../../features/hooks';
import { fetchNewsFeed } from '../../features/news/state';
import { useAnimations } from '../../useAnimation';
import './index.scss';

const News = () => {
  useAnimations();
  const dispatch = useDispatch();
  const { newsFeed, newsLoading } = useSelector((s) => s.news);

  useEffect(() => {
    if (newsFeed.length <= 0) {
      dispatch(fetchNewsFeed());
    }
  }, []);

  return (
    <>
      <Header />
      <div className="news padding no-pb">
        <div className="row">
          {newsLoading ? (
            <></>
          ) : (
            <>
              {newsFeed.map((newsItem) => (
                <div className="rtb-col">
                  <div className="post-date">{newsItem.date}</div>
                  <div>
                    <a
                      className="post-image"
                      href={newsItem.link}
                      style={{ backgroundImage: `url(${newsItem.thumbnail})` }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="sr-only">{newsItem.title}</span>
                    </a>
                  </div>
                  <div>
                    <a className="post-title" href={newsItem.link} target="_blank" rel="noreferrer">
                      <h2>{newsItem.title}</h2>
                    </a>
                  </div>
                  <div className="excerpt" dangerouslySetInnerHTML={{ __html: newsItem.excerpt }} />
                  <div className="read-more">
                    <a className="btn" href={newsItem.link} target="_blank" rel="noreferrer">
                      Read More
                    </a>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="view-more-links">
          <a
            className="btn"
            href="https://medium.com/defi-kingdoms-official"
            target="_blank"
            rel="noreferrer"
          >
            View More Articles
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
