import { ReactNode } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import styles from './SubPage.module.scss';
import cx from 'classnames';
import LearnMoreLink from '../components/LearnMoreLink';
import CoreAccordion from '../components/CoreAccordion';

interface SubPageProps {
  children?: ReactNode;
  heroHeader: string;
  heroSubheader: string;
  heroBackground: string;
  mainCopyHeader: string;
  mainCopySubheader: ReactNode;
  rightSectionImage: string;
  rightSectionHeader: string;
  rightSectionCopy: ReactNode;
  leftSectionImage: string;
  leftSectionHeader: string;
  leftSectionCopy: ReactNode;
  centerSectionImage: string;
  centerSectionHeader: string;
  centerSectionCopy: ReactNode;
  leftClassName?: string;
  rightClassName?: string;
  nextSectionLabel: string;
  nextSectionLink: string;
  subpageIndex: number;
}

function SubPage({
  children,
  heroHeader,
  heroBackground,
  heroSubheader,
  mainCopyHeader,
  mainCopySubheader,
  rightSectionHeader,
  rightSectionCopy,
  rightSectionImage,
  leftSectionHeader,
  leftSectionCopy,
  leftSectionImage,
  centerSectionHeader,
  centerSectionCopy,
  centerSectionImage,
  leftClassName,
  rightClassName,
  nextSectionLabel,
  nextSectionLink,
  subpageIndex,
}: SubPageProps) {
  return (
    <>
      <Header />
      <main className={styles.subpage}>
        {/* Hero Section */}
        <section
          className={styles.hero}
          style={{
            background: `url(${heroBackground}) center center / cover no-repeat`,
          }}
        >
          <h2>{heroHeader}</h2>
          {/* <p>{heroSubheader}</p> */}
        </section>
        <CoreAccordion subpageIndex={subpageIndex} isSubpage />
        {/* Custom Section */}
        {children}
        {/* Main Copy */}
        <section className={styles.mainCopy}>
          <div className={styles.copy}>
            <h3>{mainCopyHeader}</h3>
            <p>{mainCopySubheader}</p>
          </div>
        </section>
        {/* Right Section */}
        <section className={cx(styles.rightSection, rightClassName)}>
          <div className={styles.leftColumn}>
            <img src={rightSectionImage} alt="" />
          </div>
          <div className={styles.rightColumn}>
            <h3 className={styles.sectionHeader}>{rightSectionHeader}</h3>
            <div className={styles.rightCopy}>{rightSectionCopy}</div>
          </div>
        </section>
        {/* Left Section */}
        <section className={cx(styles.leftSection, leftClassName)}>
          <div className={styles.leftColumn}>
            <h3 className={styles.sectionHeader}>{leftSectionHeader}</h3>
            <div className={styles.rightCopy}>{leftSectionCopy}</div>
          </div>
          <div className={styles.rightColumn}>
            <img src={leftSectionImage} alt="" />
          </div>
        </section>
        {/* Center Section */}
        <section
          className={styles.centerSection}
          style={{
            background: `url(${centerSectionImage}) center center / cover no-repeat`,
          }}
        >
          <div className={styles.copy}>
            <h2>{centerSectionHeader}</h2>
            <p>{centerSectionCopy}</p>
            <a href="https://game.defikingdoms.com" className={styles.playButton}>
              Play Now
            </a>
          </div>
        </section>
        {/* Next Section */}
        <section className={styles.nextSection}>
          <LearnMoreLink label="Go To Next" link={nextSectionLink} />
          <p>{nextSectionLabel}</p>
        </section>
        <CoreAccordion subpageIndex={subpageIndex} isSubpage />
      </main>
      <Footer />
      <button className={cx(styles.backArrow)} onClick={() => (window.location.href = '/')}>
        <span>&#8962;</span>
      </button>
    </>
  );
}

export default SubPage;
