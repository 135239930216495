import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { db } from '../firebase';
import { NewsItem } from './types';
import { DateTime } from 'luxon';

type NewsSliceState = {
  newsFeed: NewsItem[];
  newsLoading: boolean;
};

const initialState: NewsSliceState = {
  newsFeed: [],
  newsLoading: true,
};

export const fetchNewsFeed = createAsyncThunk('news/fetchNewsFeed', async () => {
  const snapshot = await getDocs(
    query(collection(db, 'newsitems'), orderBy('date', 'desc'), limit(10)),
  );
  const newsItems = snapshot.docs
    .map((doc) => doc.data())
    .sort((a, b) => b.date - a.date)
    .map((newsItem) => ({
      title: newsItem.title,
      link: newsItem.link,
      excerpt: newsItem.excerpt,
      thumbnail: newsItem.thumbnail,
      date: DateTime.fromMillis(newsItem.date.seconds * 1000).toFormat('d MMM'),
    }))
    .splice(0, 10);
  return newsItems;
});

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchNewsFeed.pending, (state) => {
        state.newsLoading = true;
      })
      .addCase(fetchNewsFeed.fulfilled, (state, action) => {
        state.newsLoading = false;
        state.newsFeed = action.payload;
      })
      .addCase(fetchNewsFeed.rejected, (state, action) => {
        state.newsLoading = false;
        console.log(action.error.message);
      });
  },
});

const { reducer } = newsSlice;
export default reducer;
