import Footer from '../components/Footer';
import Header from '../components/Header';
import { useAnimations } from '../useAnimation';
import styles from './Contact.module.scss';

const Contact = () => {
  useAnimations();

  return (
    <>
      <Header />
      <div className={styles.contact}>
        <div className="content">
          <iframe
            title="Contact Form"
            src="https://docs.google.com/forms/d/e/1FAIpQLScDFERMhOSjps3BmepBQV6SE52_XVXH-8n6TktgYVroJ-pqNQ/viewform?embedded=true"
            width="640"
            height="1170"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            style={{ width: '100%' }}
            data-aos="fade-up"
          >
            Loading…
          </iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
