import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useAnimations } from '../../useAnimation';
import RoadmapSection from './components/RoadmapSection';
import Hero from './components/Hero';
import HeroDetails from './components/HeroDetails';
import Web3ActivationSection from './components/Web3ActivationSection';
import WorldExplorationSection from './components/WorldExplorationSection';
import GatherinCraftingSection from './components/GatheringCraftingSection';
import PvpSection from './components/PvpSection';
import AdventuringSection from './components/AdventuringSection';
import GetStartedSection from './components/GetStartedSection';
import CollaborateSection from './components/CollaborateSection';
import CoreAccordion from '../../components/CoreAccordion';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import PartnersSection from './components/PartnersSection';

const Home = () => {
  useAnimations();
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    const currentScroll = window.scrollY;

    if (currentScroll > 0) {
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  }

  function scrollToTop() {
    const element = document.querySelector('#heroScroll');
    element?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  return (
    <>
      <Header />
      <Hero />
      <CoreAccordion />
      <HeroDetails />
      <PvpSection />
      <AdventuringSection />
      <WorldExplorationSection />
      <GatherinCraftingSection />
      <Web3ActivationSection />
      <GetStartedSection />
      <RoadmapSection />
      <PartnersSection />
      <CollaborateSection />
      <Footer />
      <button
        className={cx(styles.returnToTopArrow, { [styles.active]: showArrow })}
        onClick={scrollToTop}
      >
        <span>⇪</span>
      </button>
    </>
  );
};

export default Home;
