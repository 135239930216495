import styles from './CoreAccordion.module.scss';
import adventure from './images/adventuring-diagonal.png';
import exploration from './images/exploration-diagonal.png';
import gathering from './images/gathering-diagonal.png';
import hero from './images/hero-diagonal.png';
import pvp from './images/pvp-diagonal.png';
import web3 from './images/web3-diagonal.png';
import placeholder from './images/placeholder.png';
import { Fragment, useState } from 'react';
import cx from 'classnames';

const accordionOptions = [
  {
    label: 'Command Heroes in a Fantasy RPG World',
    link: 'commandheroes',
    image: hero,
  },
  {
    label: 'Player vs Player',
    link: 'pvp',
    image: pvp,
  },
  {
    label: 'Adventuring',
    link: 'adventuring',
    image: adventure,
  },
  {
    label: 'World Exploration',
    link: 'exploration',
    image: exploration,
  },
  {
    label: 'Gathering & Crafting',
    link: 'gatheringcrafting',
    image: gathering,
  },
  {
    label: 'Web3 Activation',
    link: 'web3',
    image: web3,
  },
];

interface CoreAccordionProps {
  isSubpage?: boolean;
  subpageIndex?: number;
}

function CoreAccordion({ isSubpage, subpageIndex }: CoreAccordionProps) {
  const [hoveredOptionIndex, setHoveredOptionIndex] = useState<number | null>(
    typeof subpageIndex !== 'undefined' ? subpageIndex : null,
  );

  function handleMouseEnter(index: number) {
    setHoveredOptionIndex(index);
  }

  function handleMouseLeave() {
    setHoveredOptionIndex(typeof subpageIndex !== 'undefined' ? subpageIndex : null);
  }

  function clickScrollTo(elementId: string) {
    const element = document.querySelector(`#${elementId}`);
    element?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  function redirectToPage(link: string) {
    window.location.href = `${link}`;
  }

  return (
    <div className={styles.coreAccordion}>
      <div className={styles.accordionRow}>
        {accordionOptions.map((option, index) => (
          <Fragment key={option.label}>
            <div className={styles.placeholder}>
              <img src={placeholder} alt="" />
            </div>
            <button
              className={cx(styles.accordionOption, {
                [styles.active]: hoveredOptionIndex === index,
                [styles.inactive]: hoveredOptionIndex !== null && hoveredOptionIndex !== index,
              })}
              style={{ left: `${(index * 100) / 6}%`, zIndex: accordionOptions.length - index }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave()}
              onClick={
                isSubpage ? () => redirectToPage(option.link) : () => clickScrollTo(option.link)
              }
            >
              <img src={option.image} alt="" />
              <div className={styles.textBlock}>
                <p>{option.label}</p>
              </div>
            </button>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export default CoreAccordion;
