import { AttackRange, AttackRangeExt } from '../attack-range';
import './AbilityToolTipContent.scss';

type Props = {
  imgPath: string;
  abilityName: string;
  manaCost: number | undefined;
  range: AttackRange | null | undefined;
  abilityDescription: string[] | null | undefined;
};

export const AbilityToolTipContent = (props: Props) => {
  // console.log('🚀 ~ file: AbilityToolTipContent.tsx:11 ~ abilityDescription:', props.abilityDescription)
  return (
    <div className={`ability-tooltip-content`}>
      <div className="ability-img">
        <img src={props.imgPath} alt="" />
      </div>
      <div className="ability-info">
        <div className="ability-name">{props.abilityName}</div>
        <div className={'ability-mana-row'}>
          <div className={'mana-cost'}>MANA COST: {props.manaCost}</div>
          <div className={'range'}>
            RANGE: {AttackRangeExt.toDisplay(props.range).toUpperCase()}
          </div>
        </div>
        <div className="ability-description">
          <ul style={{ margin: 0, padding: 0 }}>
            {props.abilityDescription?.map((item) => (
              <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
