import SubPage from '../SubPage';
import banner from './images/web3-banner.png';
import subbanner from './images/web3-subbanner.png';
import openEconomy from './images/open-economy.png';
import utility from './images/utility.png';
import styles from './index.module.scss';
import avalancheLogo from './images/avalanche.png';
import creditcardLogo from './images/creditcard.png';
import ethereumLogo from './images/ethereum.png';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import CreditSection from './credit-section';
import AvalancheSection from './avalanche-section';
import EtherumSection from './ethereum-section';

function Web3ActivationPage() {
  const sectionRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const startingOptions = [
    {
      label: 'Credit Card',
      image: creditcardLogo,
      section: CreditSection,
    },
    {
      label: 'Avalanche',
      image: avalancheLogo,
      section: AvalancheSection,
    },
    {
      label: 'Ethereum',
      image: ethereumLogo,
      section: EtherumSection,
    },
  ];

  useEffect(() => {
    if (sectionRef.current && window.location.href.includes('#buyjewel')) {
      sectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [sectionRef.current]);

  return (
    <SubPage
      subpageIndex={5}
      heroHeader="Web3 Activation"
      heroBackground={banner}
      heroSubheader="Test your mettle against players, rise through the ranks to become a legendary PvP champion, leaving your mark on the vibrant and competitive community"
      mainCopyHeader="Own Your Experience"
      mainCopySubheader="DeFi Kingdoms is the first cross-chain play and earn game in the metaverse gaming sector. Players enjoy true ownership with the freedom to trade assets in-game, when and how they see fit. By gamifying decentralized finance, DeFi Kingdoms makes it accessible and engaging to all, unlocking a new world of possibilities."
      rightSectionHeader="Open Economy"
      rightSectionCopy={
        <>
          <p>
            Embrace an in-game economy where every asset, from the smallest Bloater to the mightiest
            DreadKnight, is tokenized. Harness the power of your time and dedication to build an
            account with real-world value through trading, staking, or collecting, and explore
            endless opportunities as you claim your legacy in this fully open and decentralized
            economy.
          </p>
        </>
      }
      rightSectionImage={openEconomy}
      leftClassName={styles.leftSection}
      leftSectionHeader="Utility-Based NFTs"
      leftSectionCopy={
        <>
          <p>
            Unleash the power of Heroes, unique NFTs that are packed with utility and serve as the
            core of the game. Through quests, resource gathering, and combat, Heroes gain
            experience, permanently improving their stats and allowing you to shape their destinies.
          </p>
          <p>
            Equip additional NFTs like Pets and Weapons to your Heroes to enhance their stats
            further, maintaining the uniqueness of each while unlocking their full potential.
          </p>
        </>
      }
      leftSectionImage={utility}
      centerSectionHeader="Decentralized Exchange"
      centerSectionCopy={
        <>
          <p>
            The in-game Marketplace serves as a vibrant trading hub, based on Uniswap V2's
            decentralized exchange (DEX) model, allowing players the opportunity to provide
            liquidity and earn a share of the collected trading fees.
          </p>
          <p>
            For low-liquidity items, the Bazaar provides an orderbook-style DEX, enabling seamless
            player-to-player transactions for whatever you may wish to buy or sell.
          </p>
        </>
      }
      centerSectionImage={subbanner}
      nextSectionLink="commandheroes"
      nextSectionLabel="Command Heroes in a Fantasy RPG World"
    >
      <section className={styles.mainCopy}>
        <span className={styles.mainCopyAnchor} ref={sectionRef} />
        <div className={styles.copy}>
          <h3>Buy JEWEL</h3>
          <p>
            Learn how to purchase JEWEL, DeFi Kingdoms' Ecosystem Token and gas token for DFK Chain.
          </p>
        </div>
        <div className={styles.startSelection}>
          <p className={styles.subheader}>Choose your starting point:</p>
          <div className={styles.optionRow}>
            {startingOptions.map((option, index) => (
              <div
                key={option.label}
                onClick={() => setActiveIndex(index)}
                className={cx(styles.option, { [styles.active]: index === activeIndex })}
              >
                <div
                  className={cx(styles.imgContainer, { [styles.eth]: option.label === 'Ethereum' })}
                >
                  <img src={option.image} alt={`${option.label} Logo`} />
                </div>
                <p>{option.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {startingOptions[activeIndex].section()}
    </SubPage>
  );
}

export default Web3ActivationPage;
