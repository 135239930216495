import styles from './AdventuringSection.module.scss';
import blub from '../img/adventuring/blub.gif';
import LearnMoreLink from '../../../components/LearnMoreLink';
import ctgScreenshot from '../img/adventuring/ctg-screenshot.png';
import ScrollOffset from '../../../components/ScrollOffset';

function AdventuringSection() {
  return (
    <section className={styles.adventuring}>
      <ScrollOffset id="adventuring" />
      <div className={styles.gamefiDetailsRow}>
        <div className={styles.columnLeft}>
          <img src={ctgScreenshot} alt="" />
        </div>
        <div className={styles.columnRight}>
          <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
            <img src={blub} alt="" />
            <h3>Adventuring</h3>
            <h4>Combat Testing Grounds</h4>
            <p>
              Prove you’re up to the task and test your abilities against all manner of mythical
              creatures.
            </p>
            <LearnMoreLink link="/adventuring" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdventuringSection;
