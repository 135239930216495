import Tippy, { TippyProps } from '@tippyjs/react';
import 'tippy.js/animations/shift-away-subtle.css';
import 'tippy.js/dist/tippy.css';

const TippyPopup = ({ children, ...props }: TippyProps) => {
  return (
    <Tippy {...props} animation={'shift-away-subtle'}>
      {children}
    </Tippy>
  );
};

export default TippyPopup;
