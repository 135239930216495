import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslations from "./locales/en/index.json";
import koTranslations from "./locales/ko/index.json";
import { supportedLanguages } from "./constants";

const localLanguageSetting = localStorage.getItem(
  "defiKingdoms_languageSetting"
);

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslations,
      },
      ko: {
        translation: koTranslations,
      },
    },
    fallbackLng: localLanguageSetting || "en",
    preload: supportedLanguages,
    keySeparator: false,
    interpolation: { escapeValue: false },
    debug: process.env.NODE_ENV === "development",
  });

i18next.on("languageChanged", function (language: string) {
  localStorage.setItem("defiKingdoms_languageSetting", language);
});

export function getCurrentLanguage() {
  return i18next.language as string;
}

export function changeLanguage(language: string) {
  return i18next.changeLanguage(language);
}

export function onLanguageChanged(callback: (language: string) => void) {
  return i18next.on("languageChanged", callback);
}

export function getTranslatedLanguage(language: string) {
  const languageName = new Intl.DisplayNames([language], { type: "language" });
  return languageName.of(language);
}

export default i18next;
