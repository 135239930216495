import SubPage from '../SubPage';
import banner from './images/gathering-banner.png';
import subbanner from './images/gathering-subbanner.png';
import gathering from './images/gathering.png';
import crafting from './images/crafting.png';
import styles from './index.module.scss';

function GatheringPage() {
  return (
    <SubPage
      subpageIndex={4}
      heroHeader="Gathering & Crafting"
      heroBackground={banner}
      heroSubheader="Test your mettle against players, rise through the ranks to become a legendary PvP champion, leaving your mark on the vibrant and competitive community"
      mainCopyHeader="Quest for Power"
      mainCopySubheader="Are you ready to take your Heroes to the next level? Set off on quests for resource gathering and crafting, harnessing raw materials to forge essential equipment and consumables. Manage your provisions wisely and empower your Heroes to overcome any challenge."
      rightSectionHeader="Gathering"
      rightSectionCopy={
        <>
          <p>
            The kingdoms are filled with an abundance of riches to gather by fishing, foraging,
            gardening, and mining their bounties. Not only are these resources necessary for
            crafting, you can even find loyal Pets to bond to your Heroes and give them a new best
            friend (and other bonuses as well)!
          </p>
        </>
      }
      rightSectionImage={gathering}
      leftClassName={styles.leftSection}
      leftSectionHeader="Crafting"
      leftSectionCopy={
        <>
          <p>
            Crafting is a timeless art, converting raw materials into essential items and supplies.
            Discover the right recipes to forge armor and weapons, brew potions, and more.
          </p>
          <p>
            Equip your Heroes with the fruits of their labor, or trade and sell with other players.
            The thirst for crafted items knows no bounds in the realms of adventure!
          </p>
        </>
      }
      leftSectionImage={crafting}
      centerSectionHeader="Workshops"
      centerSectionCopy={
        <>
          <p>
            With your resources gathered, recipes in hand, and Heroes at the ready, now all you need
            are the right tools for the job. Seek out workshops for every craft constructed on the
            land plots located throughout the realms. But be warned: their use may come at a price.
          </p>
        </>
      }
      centerSectionImage={subbanner}
      nextSectionLink="web3"
      nextSectionLabel="Web3 Activation"
    />
  );
}

export default GatheringPage;
