import { useState } from "react";
import cx from "classnames";
import { flagMap, supportedLanguages } from "../constants";
import i18next from "../utils";
import "./LanguagePicker.scss";

const LanguagePicker = () => {
  const [currentLanguage, setCurrentLanguage] = useState<string>(
    i18next.language as string
  );

  function handleLanguageChange(language: string) {
    setCurrentLanguage(language);
    i18next.changeLanguage(language);
  }

  return (
    <div className="languagePicker">
      {supportedLanguages.map((l) => (
        <div
          className={cx("language", {
            active: currentLanguage === l,
          })}
          onClick={() => handleLanguageChange(l)}
        >
          {flagMap[l]}
        </div>
      ))}
    </div>
  );
};

export default LanguagePicker;
