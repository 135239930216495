import styles from './Web3ActivationSection.module.scss';
import tradescreen from '../img/select-token.png';
import trader from '../img/trader.png';
import druid from '../img/druid.png';
import tokens from '../img/web3activation/tokens.png';
import LearnMoreLink from '../../../components/LearnMoreLink';
import ScrollOffset from '../../../components/ScrollOffset';

function Web3ActivationSection() {
  return (
    <section className={styles.gamefiDetails}>
      <ScrollOffset id="web3" offset={-110} />
      <div className={styles.gamefiDetailsRow}>
        <div className={styles.columnLeft}>
          <div data-aos="fade-right" className={styles.tradeScreenWrapper}>
            <div className={styles.imageWrapper}>
              <img src={tradescreen} className={styles.tradeScreen} alt="Token Trade screen" />
              <img className={styles.trader} src={trader} alt="" />
              <img className={styles.druid} src={druid} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.columnRight}>
          <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
            <img src={tokens} alt="" />
            <h3>Web3 Activation</h3>
            <h4>Play and Earn</h4>
            <p>
              Players are empowered to take full ownership of every asset in the game, giving you
              greater control over the game you play.
            </p>
            <LearnMoreLink link="/web3" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Web3ActivationSection;
