import { useEffect } from 'react';
import Aos from 'aos';

export function useAnimations() {
  useEffect(() => {
    Aos.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }, []);
}
