import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { TeamMember } from './types';

type TeamSliceState = {
  teamMembers: TeamMember[];
  teamMembersLoading: boolean;
};

const initialState: TeamSliceState = {
  teamMembers: [],
  teamMembersLoading: true,
};

export const fetchTeamMembers = createAsyncThunk('news/fetchTeamMembers', async () => {
  const snapshot = await getDocs(collection(db, 'teammembers'));
  const teammembers = snapshot.docs
    .map((doc) => doc.data())
    .map((teammember) => ({
      name: teammember.name,
      title: teammember.title,
      startDate: teammember.startDate,
      thumbnail: teammember.thumbnail,
      whiteBackground: teammember.whiteBackground,
      socials: teammember.socials || [],
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
  console.log(teammembers);
  return teammembers;
});

const teamMembersSlice = createSlice({
  name: 'teammembers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamMembers.pending, (state) => {
        state.teamMembersLoading = true;
      })
      .addCase(fetchTeamMembers.fulfilled, (state, action) => {
        state.teamMembersLoading = false;
        state.teamMembers = action.payload;
      })
      .addCase(fetchTeamMembers.rejected, (state, action) => {
        state.teamMembersLoading = false;
        console.log(action.error.message);
      });
  },
});

const { reducer } = teamMembersSlice;
export default reducer;
