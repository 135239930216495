import { Action, configureStore, getDefaultMiddleware, ThunkDispatch } from '@reduxjs/toolkit';
import { load } from 'redux-localstorage-simple';
import { updateVersion } from './actions';
import news from './news/state';
import team from './team/state';

export const reducers = {
  news,
  team,
};

const store = configureStore({
  reducer: reducers,
  middleware: [
    ...getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: load({ disableWarnings: true }),
});

store.dispatch(updateVersion());

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<AppState, {}, Action>;

interface StoreUtils {
  dispatch: AppDispatch;
  getState: typeof store.getState;
}

export const { dispatch, getState }: StoreUtils = store;
export default store;
