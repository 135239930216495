export enum AttackRange {
  Range1,
  Range2,
  Range3,
  Allies
}

export class AttackRangeExt {
  static toDisplay(side: AttackRange | null | undefined): string {
    switch (side) {
      case AttackRange.Range1:
        return '1'
      case AttackRange.Range2:
        return '2'
      case AttackRange.Range3:
        return '3'
      case AttackRange.Allies:
        return 'Allies'
      default:
        return 'Unknown'
    }
  }
}
