import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useAnimations } from '../../useAnimation';

const BugReport = () => {
  useAnimations();

  return (
    <>
      <Header />
      <div className="contact padding no-pb">
        <div className="content">
          <iframe
            title="Bug Reports"
            src="https://docs.google.com/forms/d/e/1FAIpQLSenZTgRtvFoLk05W8o1pKDpjtgtU_bdfdQUPMiMdqXXu73z4g/viewform?embedded=true"
            width="640"
            height="1260"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            style={{ width: '100%' }}
            data-aos="fade-up"
          >
            Loading…
          </iframe>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BugReport;
