import styles from './RoadmapSection.module.scss';

function RoadmapSection() {
  return (
    <section className={styles.roadmap}>
      <div className={styles.container}>
        <h2>Roadmap</h2>
        <p>Since DeFi Kingdoms launched in August 2021:</p>
        <div className={styles.accomplishments}>
          <div className={styles.success}>
            <p className={styles.number}>40+</p>
            <p className={styles.item}>Feature Releases</p>
          </div>
          <div className={styles.success}>
            <p className={styles.number}>60+</p>
            <p className={styles.item}>Developer AMAs</p>
          </div>
          <div className={styles.success}>
            <p className={styles.number}>15+</p>
            <p className={styles.item}>Governance Votes</p>
          </div>
          <div className={styles.success}>
            <p className={styles.number}>700,000+</p>
            <p className={styles.item}>Heroes Summoned</p>
          </div>
        </div>
        <p>
          Check out how DeFi Kingdoms will continue to grow with new features, systems, maps, and
          cross-chain gameplay!
        </p>
        <a
          className={styles.externalButton}
          href="https://docs.defikingdoms.com/roadmap"
          target="_blank"
          rel="noreferrer"
        >
          View Full Roadmap
        </a>
      </div>
    </section>
  );
}

export default RoadmapSection;
