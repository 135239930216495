import styles from './HeroDetails.module.scss';
import priestAnimation from '../img/herodetails/priest-animation.png';
import archerAvatars from '../img/herodetails/archer-avatars.png';
import knightAvatars from '../img/herodetails/knight-avatars.png';
import priestAvatars from '../img/herodetails/priest-avatars.png';
import priestFull from '../img/herodetails/priest-final.png';
import knightFull from '../img/herodetails/knight-final.png';
import archerFull from '../img/herodetails/archer-final.png';
import priestThumb from '../img/herodetails/priest-thumb.png';
import knightThumb from '../img/herodetails/knight-thumb.png';
import archerThumb from '../img/herodetails/archer-thumb.png';
import priestActive from '../img/herodetails/priest-active.png';
import knightActive from '../img/herodetails/knight-active.png';
import archerActive from '../img/herodetails/archer-active.png';
import LearnMoreLink from '../../../components/LearnMoreLink';
import divineBarrier from '../img/herodetails/divine-barrier.png';
import divinePrayer from '../img/herodetails/divine-prayer.png';
import holyWrath from '../img/herodetails/holy-wrath.png';

import flurry from '../img/herodetails/flurry.png';
import multiShot from '../img/herodetails/multi-shot.png';
import repeatingShot from '../img/herodetails/repeating-shot.png';
import rapidShot from '../img/herodetails/rapid-shot.png';
import barrage from '../img/herodetails/barrage.png';

import shieldSmash from '../img/herodetails/shield-smash.png';
import interception from '../img/herodetails/interception.png';
import ironWill from '../img/herodetails/iron-will.png';
import forHonor from '../img/herodetails/for-honor.png';
import valorAndTruth from '../img/herodetails/valor-and-truth.png';

import prayerOfClarity from '../img/herodetails/prayer-of-clarity.png';
import prayerOfMight from '../img/herodetails/prayer-of-might.png';
import ScrollOffset from '../../../components/ScrollOffset';
import cx from 'classnames';
import { useState } from 'react';
import TippyPopup from '../../../components/TippyPopup';
import { PassiveAbilityToolTipContent } from '../../../components/PassiveAbilityToolTipContent';
import { AbilityToolTipContent } from '../../../components/AbilityToolTipContent';
import { AttackRange } from '../../../attack-range';
import plusIcon from '../img/herodetails/plus-icon.png';

enum AttackStyle {
  Range,
  Melee,
  Passive,
  Self,
}

interface HeroSkill {
  attackStyle?: AttackStyle;
  image: string;
  manaCost: number;
  range?: AttackRange;
  description: string[];
  name: string;
}

const heroInfo = [
  {
    class: 'Priest',
    animation: priestAnimation,
    avatars: priestAvatars,
    image: priestFull,
    skills: [
      {
        name: 'Divine Barrier',
        description: [
          "All allies gain a Spell Barrier of holy light equal to 10% of target's max HP.",
        ],
        image: divineBarrier,
        attackStyle: AttackStyle.Range,
        range: AttackRange.Allies,
        manaCost: 10,
      },
      {
        name: 'Divine Prayer',
        description: ['5% effective INT increase in combat.'],
        image: divinePrayer,
        attackStyle: AttackStyle.Passive,
        manaCost: 0,
      },
      {
        name: 'Holy Wrath',
        description: [
          `A Holy Discipline`,
          `Deal magical damage equal to (Spell + 2.0 * INT) to target enemy.`,
          'COMBO: If this Hero used a Holy spell on its last Turn, Holy Wrath has a 99% chance to Critical Strike.',
        ],
        image: holyWrath,
        attackStyle: AttackStyle.Range,
        range: AttackRange.Range3,
        manaCost: 10,
      },
      {
        name: 'Prayer of Clarity',
        description: [
          `Heal target ally for (Spell + WIS + 0.5 * INT).`,
          'Target ally gains 80% resistance to Fear and Silence for a Duration of 2.',
        ],
        image: prayerOfClarity,
        attackStyle: AttackStyle.Range,
        range: AttackRange.Allies,
        manaCost: 10,
      },
      {
        name: 'Prayer of Might',
        description: [
          `Heal target ally for (Spell + WIS + 0.5 * INT)}.`,
          `Target ally gains effective STR equal to X% (X = INT) for a Duration of 2.`,
        ],
        image: prayerOfMight,
        attackStyle: AttackStyle.Range,
        range: AttackRange.Allies,
        manaCost: 10,
      },
    ],
    thumb: priestThumb,
    activeThumb: priestActive,
  },
  {
    class: 'Knight',
    animation: priestAnimation,
    avatars: knightAvatars,
    image: knightFull,
    skills: [
      {
        name: 'Shield Smash',
        description: [
          `Deal physical damage to target enemy equal to 1.1 * Basic.`,
          'Stun enemy target.',
        ],
        attackStyle: AttackStyle.Melee,
        range: AttackRange.Range1,
        manaCost: 10,
        image: shieldSmash,
      },
      {
        name: 'Interception',
        description: [
          'Redirect 50% of all physical damage dealt by enemies in P1 to this Hero for the remainder of Battle.',
        ],
        attackStyle: AttackStyle.Melee,
        range: AttackRange.Range1,
        manaCost: 10,
        image: interception,
      },
      {
        name: 'Iron Will',
        description: [
          'Deal physical damage to target enemy equal to (1.1*Basic).',
          'Silence target enemy for 1 Round.',
          'Allies gain 40% resistance to Silence for 3 Rounds.',
          'COMBO: If "For the King" is active, allies gain 70% resistance to Silence for 3 Rounds instead.',
        ],
        attackStyle: AttackStyle.Melee,
        range: AttackRange.Range1,
        manaCost: 10,
        image: ironWill,
      },
      {
        name: 'For Honor',
        description: [
          'Reduce ranged physical damage taken by all allies equal to X% (X = 0.06*END).',
        ],
        attackStyle: AttackStyle.Passive,
        manaCost: 0,
        image: forHonor,
      },
      {
        name: 'Valor and Truth',
        description: ['5% effective STR increase in combat.'],
        attackStyle: AttackStyle.Passive,
        manaCost: 0,
        image: valorAndTruth,
      },
    ],
    thumb: knightThumb,
    activeThumb: knightActive,
  },
  {
    class: 'Archer',
    animation: priestAnimation,
    avatars: archerAvatars,
    image: archerFull,
    skills: [
      {
        name: 'Flurry',
        description: ['Increase effective Basic Attack damage by 5% in combat.'],
        attackStyle: AttackStyle.Passive,
        manaCost: 0,
        image: flurry,
      },
      {
        name: 'Multi-shot',
        description: [
          'Fire 3 arrows randomly targeting up to 3 enemies.',
          `Each arrow deals physical damage equal to 1.25 * Basic.`,
          'COMBO: If this Hero used Multi-Shot on their previous Turn, increase Critical Strike Chance by 30%.',
        ],
        attackStyle: AttackStyle.Range,
        range: AttackRange.Range3,
        manaCost: 10,
        image: multiShot,
      },
      {
        name: 'Repeating Shot',
        description: [
          'Fire 3 arrows at target enemy.',
          `Each arrow deals physical damage equal to 1.5 * Basic.`,
          `COMBO: If this Hero targeted the same target with Rapid Shot on this Hero's previous Turn, deal additional physical damage equal to 4.0 * DEX for each instance of damage dealt.`,
        ],
        attackStyle: AttackStyle.Range,
        range: AttackRange.Range3,
        manaCost: 10,
        image: repeatingShot,
      },
      {
        name: 'Rapid Shot',
        description: [
          'Fire 3 arrows randomly targeting up to 3 enemies.',
          `Each arrow deals physical damage equal to 1.25 * Basic.`,
          'COMBO: If this Hero used Multi-Shot on their previous Turn, increase Critical Strike Chance by 30%.',
        ],
        attackStyle: AttackStyle.Range,
        range: AttackRange.Range3,
        manaCost: 10,
        image: rapidShot,
      },
      {
        name: 'Barrage',
        description: [
          'Fire 6 arrows arching high into the air, dealing physical damage randomly amongst all enemy Positions.',
          `Each arrow deals physical damage equal to 0.7 * Basic.`,
          'However, each additional arrow to strike the same enemy deals additional physical LCK damage.',
          'If there is no Hero in the Position, the damage for that arrow fizzles.',
          `Example: 2nd Arrow = 2 * LCK; 3rd Arrow = 4 * LCK; 4th Arrow = 6 * LCK; 5th Arrow = 8 * LCK.`,
        ],
        attackStyle: AttackStyle.Range,
        range: AttackRange.Range3,
        manaCost: 10,
        image: barrage,
      },
    ],
    thumb: archerThumb,
    activeThumb: archerActive,
  },
];

function HeroDetails() {
  const [activeClassIndex, setActiveClassIndex] = useState(0);

  function updateDisplayClass(index: number) {
    setActiveClassIndex(index);
  }

  function buildTooltipContent(skill: HeroSkill) {
    if (skill.attackStyle === AttackStyle.Passive) {
      return (
        <PassiveAbilityToolTipContent
          imgPath={skill.image}
          abilityName={skill.name}
          abilityDescription={skill.description}
        />
      );
    }
    return (
      <AbilityToolTipContent
        imgPath={skill.image}
        abilityName={skill.name}
        manaCost={skill.manaCost}
        range={skill.range}
        abilityDescription={skill.description}
      />
    );
  }

  return (
    <section className={styles.heroDetails}>
      <ScrollOffset id="commandheroes" offset={-66} />
      <div className={styles.copyHeader}>
        <h3>Command Heroes in a Fantasy RPG World</h3>
        <p>
          You are the Commander. Build your legion of unique Heroes, set out for glory, and make
          your mark on the world.
        </p>
        <LearnMoreLink link="/commandheroes" />
      </div>
      <div className={styles.heroDetailContainer}>
        {heroInfo.map((hero, index) => (
          <div
            className={cx(styles.heroRow, styles[hero.class.toLowerCase()], {
              [styles.active]: activeClassIndex === index,
              [styles.heroRowAbsolute]: index !== 0,
            })}
          >
            <div className={styles.leftColumn}>
              <img src={hero.image} alt="" />
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.heroThumbs}>
                {heroInfo.map((subhero, subindex) => (
                  <button
                    className={cx({ [styles.active]: subhero.class === hero.class })}
                    onClick={() => updateDisplayClass(subindex)}
                  >
                    <img
                      src={subhero.class === hero.class ? subhero.activeThumb : subhero.thumb}
                      alt=""
                    />
                  </button>
                ))}
              </div>
              <div className={styles.heroSkills}>
                {hero.skills?.map((skill: HeroSkill) => (
                  <TippyPopup key={skill.image} content={buildTooltipContent(skill)}>
                    <img key={skill.image} src={skill.image} alt="" />
                  </TippyPopup>
                ))}
                <TippyPopup
                  content={
                    <div style={{ padding: 16 }}>
                      <h3 style={{ fontSize: 20, textAlign: 'center' }}>More Skills</h3>
                      <p style={{ margin: 0 }}>10 Additional Skills and Counting</p>
                    </div>
                  }
                >
                  <img style={{ width: 25, height: 25 }} src={plusIcon} alt="" />
                </TippyPopup>
              </div>
              <p className={styles.combatskills}>Combat Skills</p>
              <div className={styles.heroInfoRow}>
                <img className={styles.avatars} src={hero.avatars} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default HeroDetails;
