import cx from 'classnames';
import styles from './index.module.scss';
import trader from './images/trader.png';
import outpost from './images/outpost.png';

function AvalancheSection() {
  return (
    <section className={cx(styles.tutorialSection, styles.avalancheSection)}>
      <div className={styles.stepOne}>
        <h3 className={styles.sectionHeader}>Step 1: Configure Wallet to Avalanche C-Chain</h3>
        <p className={styles.text}>
          If you already have AVAX in your wallet, visit game.defikingdoms.com to enter the Outpost
          and proceed to <strong>Step 2</strong>!
        </p>
      </div>
      <div className={cx(styles.rightSection)}>
        <div className={styles.leftColumn}>
          <img src={outpost} alt="" />
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.sectionHeader}>Step 2: Bridge</h3>
          <div className={styles.rightCopy}>
            <p>
              <strong>At the Outpost, click on Snow Sage Ellia.</strong> Click on{' '}
              <strong>Move AVAX to Get Started</strong> where you will find a bridge that allows you
              to move your AVAX to DFK Chain.
            </p>
            <p>
              Our internal bridge is supported behind the scenes by{' '}
              <strong>Synapse Messaging Protocol</strong>. If you have any issues with the current
              internal bridge or want more granular transaction history, check out their bridge.
            </p>
            <p>
              Once your assets are bridged, select the Travel to Crystalvale option from Snow Sage
              Ellia to add the DFKChain RPC to your wallet and connect.
            </p>
            <div className={styles.buttonWrapperLeft}>
              <a
                href="https://game.defikingdoms.com"
                className={styles.playButton}
                target="_blank"
                rel="noreferrer"
              >
                Enter The Outpost
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles.rightSection)}>
        <div className={styles.leftColumn}>
          <img className={styles.traderImage} src={trader} alt="" />
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.sectionHeader}>Step 3: Swap for JEWEL</h3>
          <div className={styles.rightCopy}>
            <p>
              Once you bridge, you will receive a small amount of gas for free, so that you can swap
              your AVAX for JEWEL at the Marketplace. Click on the <strong>Trader NPC</strong> to
              open the DeFi Kingdoms DEX.
            </p>
            <div className={styles.buttonWrapperLeft}>
              <a
                href="https://game.defikingdoms.com/marketplace"
                className={styles.playButton}
                target="_blank"
                rel="noreferrer"
              >
                Visit The Marketplace
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AvalancheSection;
