import SubPage from '../SubPage';
import banner from './images/pvp-banner.png';
import subbanner from './images/esports.png';
import duelScreen from './images/duel-screen.png';
import ladderScreen from './images/ladder-screen.png';
import styles from './index.module.scss';

function PvpPage() {
  return (
    <SubPage
      subpageIndex={1}
      heroHeader="Player vs Player"
      heroBackground={banner}
      heroSubheader="Test your mettle against players, rise through the ranks to become a legendary PvP champion, leaving your mark on the vibrant and competitive community"
      mainCopyHeader="Party Synergy"
      mainCopySubheader={
        <>
          <p>
            Command your Heroes on the battlefield and emerge victorious! With countless
            possibilities, building a cohesive team and unlocking their collective potential will
            position you for success.
          </p>
          <p>
            Whether you are the aggressor or take a defensive stance, only one thing matters:
            defeating your opponent.
          </p>
        </>
      }
      rightSectionHeader="DFK Duel"
      rightSectionCopy={
        <>
          <p>
            Unleash your Heroes in this TCG-style player vs player minigame. Engage and face off
            against opponents based on randomly selected Hero stats.
          </p>
          <p>
            But stay vigilant, as rotating bonuses can tip the scales between emerging triumphant
            and facing defeat. Embrace the thrill of chance while wielding the power of
            player-driven decisions.
          </p>
        </>
      }
      rightSectionImage={duelScreen}
      leftClassName={styles.leftSection}
      leftSectionHeader="Ladder Play & Tournaments"
      leftSectionCopy={
        <>
          <p>
            Arise as the champion among champions! Ascend the ranks in global ladder play or enter
            as a new challenger in regional tournaments.
          </p>
          <p>
            Compete against fellow players in 3v3 Hero Combat or in DFK Duels, testing your mettle
            and acumen to claim victory and etch your name in the annals of greatness.
          </p>
        </>
      }
      leftSectionImage={ladderScreen}
      centerSectionHeader="eSports"
      centerSectionCopy={
        <>
          <p>
            Free your competitive spirit on the grand stage! With our replayable and streamable
            combat system, success is even sweeter when others are watching. Share your strategies,
            showcase your triumphs, and revel in the exhilaration of eSports glory!
          </p>
        </>
      }
      centerSectionImage={subbanner}
      nextSectionLink="adventuring"
      nextSectionLabel="Adventuring"
    />
  );
}

export default PvpPage;
