import styles from './index.module.scss';
import cx from 'classnames';
import halliday from './images/halliday.png';
import singularity from './images/singularity.png';
import swipelux from './images/swipelux.png';
import onmeta from './images/onmeta.png';
import onrampModal from './images/onramp-modal.png';

function CreditSection() {
  const onrampLogos = [
    {
      label: 'halliday',
      image: halliday,
    },
    {
      label: 'singularity',
      image: singularity,
    },
    {
      label: 'swipelux',
      image: swipelux,
    },
    {
      label: 'onmeta',
      image: onmeta,
    },
  ];

  return (
    <section className={cx(styles.tutorialSection, styles.creditSection)}>
      <div className={styles.textSection}>
        <h3 className={styles.sansHeader}>Onramps</h3>
        <p className={styles.text}>
          DeFi Kingdoms partners with numerous onramps worldwide to make it easy to purchase JEWEL
          with a credit or debit card.
        </p>
        <div className={styles.logoRow}>
          {onrampLogos.map((logo) => (
            <img key={logo.label} src={logo.image} alt="" />
          ))}
        </div>
        <p className={styles.text}>
          Visit the DeFi Kingdoms Docks zone, click on the Injured Sailor (next to Chef Wally), log
          in with a DFK Smart Account if you don't already have a wallet, then purchase JEWEL from
          your onramp of choice!
        </p>
        <div className={styles.buttonWrapper}>
          <a
            href="https://game.defikingdoms.com/docks"
            className={styles.playButton}
            target="_blank"
            rel="noreferrer"
          >
            Visit The Docks
          </a>
        </div>
      </div>
      <div className={styles.onrampBanner}>
        <img className={styles.modalImage} src={onrampModal} alt="" />
      </div>
    </section>
  );
}

export default CreditSection;
