import './index.scss';
import logo from '../../img/DFK-logo.png';

const Social = () => {
  return (
    <>
      <div className="socialContainer">
        <div className="tutorialOverlay">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <div className="fancyModal show socialList">
            <div className="tutorialContent">
              <div className="fancyTitle">Socials</div>

              <div className="tutorial">
                <ul className="social list-unstyled">
                  <li className="icon">
                    <a
                      className="twitter"
                      href="https://twitter.com/DefiKingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="reddit"
                      href="https://www.reddit.com/r/DefiKingdoms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Reddit
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/defikingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/defikingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="youtube"
                      href="https://www.youtube.com/c/defikingdoms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      YouTube
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="instagram"
                      href="https://www.instagram.com/defikingdoms/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="facebook"
                      href="https://www.facebook.com/defikingdomsofficial"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="fancyModal show socialList" style={{ marginTop: 20 }}>
            <div className="tutorialContent">
              <div className="fancyTitle">Intl. Discords</div>

              <div className="tutorial">
                <ul className="social list-unstyled">
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/NX8ajTSsTA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Japanese Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/dfk-chinese"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Chinese Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/j4sSkCdnWt"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Russian Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/Fh3H7CMdbS"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Italian Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/defikingdomsph"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Filipino Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/nZD5aDhAw2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spanish Discord
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="discord"
                      href="https://discord.gg/wfGUqtmdMT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Malaysian / Singaporean Discord
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="fancyModal show socialList" style={{ marginTop: 20 }}>
            <div className="tutorialContent">
              <div className="fancyTitle">Intl. Telegrams</div>

              <div className="tutorial">
                <ul className="social list-unstyled">
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DefiKingdomsChinese"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Chinese Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DeFiKingdomsBrazil"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Portuguese Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DeFiKingdomsEsp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Spanish Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DefiKingdoms_IT"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Italian Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DeFiKingdomsID"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Indonesian Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DeFiKingdomsPH"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Filipino Telegram
                    </a>
                  </li>
                  <li className="icon">
                    <a
                      className="telegram"
                      href="https://t.me/DefiKingdomsRu"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Russian Telegram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Social;
