import styles from './GatheringCraftingSection.module.scss';
import forager from '../img/gatheringcrafting/foraging.gif';
import craftingModal from '../img/gatheringcrafting/craftingmodal.png';
import LearnMoreLink from '../../../components/LearnMoreLink';
import ScrollOffset from '../../../components/ScrollOffset';

function GatherinCraftingSection() {
  return (
    <section className={styles.gatheringCrafting}>
      <ScrollOffset id="gatheringcrafting" />
      <div className={styles.columnLeft}>
        <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
          <img src={forager} alt="Forager" />
          <h3>Gathering & Crafting</h3>
          <h4>Create Useful Items</h4>
          <p>
            Send Heroes on Quests and gather resources that can be used in Crafting, Summoning, Pet
            Hatching, and more.
          </p>
          <LearnMoreLink link="/gatheringcrafting" />
        </div>
      </div>
      <div className={styles.columnRight}>
        <img src={craftingModal} alt="" />
      </div>
    </section>
  );
}

export default GatherinCraftingSection;
