import styles from './Hero.module.scss';
import logo from '../img/dfk-logo.png';
import hyperplay from '../img/hyperplay-text.png';

function Hero() {
  return (
    <div className={styles.hero} id="hero">
      <span className={styles.heroScroll} id="heroScroll" />
      <div className={styles.heroContent}>
        <img data-aos="fade-up" className={styles.logo} src={logo} alt="DeFi Kingdoms Logo" />
        <h2 data-aos="fade-up" data-aos-delay="200">
          Command Your Legion, Claim Your Legacy
        </h2>
        <div data-aos="fade-up" data-aos-delay="300" className={styles.buttonWrapper}>
          <a href="/web3#buyjewel" className={styles.learnButton}>
            Buy JEWEL
          </a>
          <a href="https://game.defikingdoms.com" className={styles.playButton}>
            Play Now
          </a>
        </div>
        <p className={styles.or}>OR</p>
        <div className={styles.hyperplay}>
          <a href="https://store.hyperplay.xyz/game/defikingdoms" target="_blank" rel="noreferrer">
            <img src={hyperplay} alt="Play with Hyperplay" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
