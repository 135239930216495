import styles from './PvpSection.module.scss';
import dfkDuelLogo from '../img/pvp/dfk-duel.png';
import LearnMoreLink from '../../../components/LearnMoreLink';
import duelScreenshot from '../img/pvp/duel-screenshot.png';
import ScrollOffset from '../../../components/ScrollOffset';

function PvpSection() {
  return (
    <section className={styles.pvp}>
      <ScrollOffset id="pvp" />
      <div className={styles.columnLeft}>
        <div className={styles.copy} data-aos="fade-up" data-aos-delay="200">
          <img src={dfkDuelLogo} alt="DFK Duel" />
          <h3>Player vs Player</h3>
          <h4>DFK DUEL</h4>
          <p>
            Battle it out with other players and rise through the ranks to become a legendary
            champion.
          </p>
          <LearnMoreLink link="/pvp" />
        </div>
      </div>
      <div className={styles.columnRight}>
        <img src={duelScreenshot} alt="" />
      </div>
    </section>
  );
}

export default PvpSection;
