import SubPage from '../SubPage';
import banner from './images/adventure-banner.png';
import subbanner from './images/exploration-subbanner.png';
import resources from './images/resources.png';
import build from './images/build.png';
import styles from './index.module.scss';

function ExplorationPage() {
  return (
    <SubPage
      subpageIndex={3}
      heroHeader="World Exploration"
      heroBackground={banner}
      heroSubheader="Test your mettle against players, rise through the ranks to become a legendary PvP champion, leaving your mark on the vibrant and competitive community"
      mainCopyHeader="Explore the Realms"
      mainCopySubheader="Heroes are nothing if not adventurers at heart. Embark on a journey across Serendale and Crystalvale, where every corner holds the promise of excitement and untold treasures. There’s a whole world out there—don’t stay in one place too long!"
      rightSectionHeader="Unique Resources"
      rightSectionCopy={
        <>
          <p>
            Unlock the secrets of Gaia's bounty. Explore diverse landscapes where distinct resources
            thrive under regional conditions.
          </p>
          <p>
            Whether scouring lush forests for rare flora, tracking elusive fauna in desolate
            snow-covered lands, or delving into mineral-rich caverns, the location you choose will
            determine what treasures await and the abundance you can gather.
          </p>
        </>
      }
      rightSectionImage={resources}
      leftClassName={styles.leftSection}
      leftSectionHeader="Build the Kingdom"
      leftSectionCopy={
        <>
          <p>
            Earn coveted deeds to lands and build out your own territory, becoming the ruler of your
            domain. Construct buildings for trade, establish a stronghold for your Heroes, and forge
            a base of operations.
          </p>
          <p>
            Unite with others by forming guilds and fortify your land against lurking adversaries
            using strategic defenses to safeguard your territory from relentless attacks.
          </p>
        </>
      }
      leftSectionImage={build}
      centerSectionHeader="Shrewd Positioning"
      centerSectionCopy={
        <>
          <p>
            Heroes can’t be everywhere at once and traveling between vast regions takes precious
            time. As Commander, it is up to you to stage your legion to take advantage of
            time-limited events and opportunities. Will your forces flourish in Serendale or
            Crystalvale, or will they traverse both lands, harnessing the diverse and unique
            potential of each realm?
          </p>
        </>
      }
      centerSectionImage={subbanner}
      nextSectionLink="gatheringcrafting"
      nextSectionLabel="Gathering & Crafting"
    />
  );
}

export default ExplorationPage;
