import BigButton from '../../../components/BigButton';
import styles from './CollaborateSection.module.scss';

function CollaborateSection() {
  function navigateToContact() {
    window.location.href = '/contact';
  }

  return (
    <section className={styles.collaborate}>
      <h3>Collaborate with Us</h3>
      <p>
        It takes a village to build the kingdoms. We are always open to all forms of collaboration
        and cooperation. Reach out to our team today!
      </p>
      <BigButton label="Get In Touch" onClick={navigateToContact} />
    </section>
  );
}

export default CollaborateSection;
