import './PassiveAbilityToolTipContent.scss'

type Props = {
  imgPath: string
  abilityName: string
  abilityDescription: string[] | null | undefined
}

export const PassiveAbilityToolTipContent = (props: Props) => {
  return (
    <div className={'passive-ability-tooltip-content'}>
      <div className="passive-ability-img">
        <img src={props.imgPath} alt="" />
      </div>
      <div className="passive-ability-info">
        <div className="passive-ability-name">{props.abilityName}</div>
        <div className={'passive-ability-mana-row'}>
          <div className={'mana-cost'}>PASSIVE</div>
        </div>
        <div className="passive-ability-description">
          <ul style={{ margin: 0, padding: 0 }}>
            {props.abilityDescription?.map(item => (
              <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
