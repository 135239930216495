import styles from './ScrollOffset.module.scss';

interface ScrollOffsetProps {
  id: string;
  offset?: number;
}

function ScrollOffset({ id, offset }: ScrollOffsetProps) {
  return <div className={styles.scrollOffset} id={id} style={{ top: offset || -72 }} />;
}

export default ScrollOffset;
