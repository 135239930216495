import styles from './LearnMoreLink.module.scss';

interface LearnMoreLinkProps {
  label?: string;
  link: string;
  target?: string;
}

function LearnMoreLink({ label, link, target }: LearnMoreLinkProps) {
  return (
    <a className={styles.learnMoreLink} href={link} target={target} rel="noreferrer">
      {label || 'Learn More'} &#9656;
    </a>
  );
}

export default LearnMoreLink;
