import cx from 'classnames';
import styles from './index.module.scss';
import dfkchain from './images/dfkchain.png';
import synapse from './images/synapse.png';
import traderEth from './images/trader-eth.png';

function EtherumSection() {
  return (
    <section className={cx(styles.tutorialSection, styles.ethereumSection)}>
      <div className={styles.stepOne}>
        <h3 className={styles.sectionHeader}>Step 1: Configure Wallet to ETHEREUM NETWORK</h3>
        <p className={styles.text}>
          If you already have ETH in your wallet, visit Synapse Protocol to begin the bridging
          process to DFK Chain.
        </p>
      </div>
      <div className={cx(styles.rightSection)}>
        <div className={styles.leftColumn}>
          <img className={styles.ethImage} src={synapse} alt="" />
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.sectionHeader}>Step 2: Bridge ETH</h3>
          <div className={styles.rightCopy}>
            <p>
              Connect your wallet to Synapse and input the amount of ETH you would like to bridge to
              DFK Chain.
            </p>
            <div className={styles.buttonWrapperLeft}>
              <a
                href="https://www.synapseprotocol.com/?fromChainId=1&fromToken=ETH&toChainId=53935&toToken=ETH"
                className={styles.playButton}
                target="_blank"
                rel="noreferrer"
              >
                Bridge With Synapse
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles.rightSection)}>
        <div className={styles.leftColumn}>
          <img src={dfkchain} alt="" />
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.sectionHeader}>Step 3: Enter Crystalvale</h3>
          <div className={styles.rightCopy}>
            <p>
              After bridging, visit game.defikingdoms.com and click “Enter Crystalvale” to add the
              DFKChain RPC to your wallet.
            </p>
            <div className={styles.buttonWrapperLeft}>
              <a
                href="https://game.defikingdoms.com/"
                className={styles.playButton}
                target="_blank"
                rel="noreferrer"
              >
                Connect to DFK Chain
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles.rightSection)}>
        <div className={styles.leftColumn}>
          <img src={traderEth} alt="" />
        </div>
        <div className={styles.rightColumn}>
          <h3 className={styles.sectionHeader}>Step 4: Swap for JEWEL</h3>
          <div className={styles.rightCopy}>
            <p>
              Once you bridge, you will receive a small amount of gas for free, so that you can swap
              your ETH for JEWEL at the Marketplace. Click on the <strong>Trader NPC</strong> to
              open the DeFi Kingdoms DEX.
            </p>
            <div className={styles.buttonWrapperLeft}>
              <a
                href="https://game.defikingdoms.com/marketplace"
                className={styles.playButton}
                target="_blank"
                rel="noreferrer"
              >
                Visit the Marketplace
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EtherumSection;
