import SubPage from '../SubPage';
import banner from './images/command-banner.png';
import heroCollection from './images/hero-collection.png';
import subbanner from './images/command-subbanner.png';
import inventory from './images/inventory.png';
import styles from './index.module.scss';

function CommandHeroesPage() {
  return (
    <SubPage
      subpageIndex={0}
      heroHeader="Command Heroes in a Fantasy RPG World"
      heroBackground={banner}
      heroSubheader="Experience immersive gameplay where your decisions impact outcomes"
      mainCopyHeader="You Are The Commander"
      mainCopySubheader="Take charge and assemble a legion of Heroes under your command as you embark on epic adventures across the realms of Serendale and Crystalvale, venture on quests for vital resources, prepare your forces for battle, and lead them to victory!"
      rightSectionHeader="Hero Selection"
      rightSectionCopy={
        <>
          <p>
            Every Hero is unique, with a distinct class, skills, and playstyle of their own. Some
            may excel in combat, while others might specialize in gathering or crafting.
          </p>
          <p>
            Allocate their skills as they gain experience, shaping them into formidable champions or
            skilled tradespeople. Build your legion with care to ensure your Heroes are up to any
            task.
          </p>
        </>
      }
      rightSectionImage={heroCollection}
      leftClassName={styles.leftSection}
      leftSectionHeader="Resource Management"
      leftSectionCopy={
        <>
          <p>
            As a Commander, it falls upon you to maintain the strength and preparedness of your
            legion. How will you secure the necessary Gold and equipment to empower your Heroes?
          </p>
          <p>
            Choose between gathering materials and crafting your own consumables and supplies or
            purchasing them from others. There are no right or wrong choices, for every Commander's
            journey is unique and defined by their own path.
          </p>
        </>
      }
      leftSectionImage={inventory}
      centerSectionHeader="Tactical Role-playing Elements"
      centerSectionCopy={
        <>
          <p>
            Craft a battle plan that aligns with your unique playstyle and the abilities of your
            Heroes, strategically combining their strengths and skills to unleash devastating
            attacks and overcome even the most formidable foes.
          </p>
          <p>
            With every decision you make, you shape the battlefield and pave your way to greatness
            in the realms!
          </p>
        </>
      }
      centerSectionImage={subbanner}
      nextSectionLink="pvp"
      nextSectionLabel="Player vs Player"
    />
  );
}

export default CommandHeroesPage;
